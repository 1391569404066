<script setup lang="ts">
analytics.page({
  name: 'Allowance Card CTC',
  category: 'LP',
  product: 'medicare',
})

const card = ref(null)

const { height } = useElementSize(card)

const name = computed(() => {
  const firstname = query.firstname?.trim() || ''
  const lastname = query.lastname?.trim() || ''
  const fullName = `${firstname} ${lastname}`.trim()
  return fullName.length > 15 && firstname && lastname
    ? `${firstname.charAt(0)} ${lastname}`
    : fullName
})
</script>

<template>
  <Layout
    :hide-header="true"
    footer="2024"
    :class="
      [
        'bg-[#f7f7f7]',
        'flex flex-col min-h-[100vh] font-dm items-center relative',
      ].join(' ')
    "
  >
    <span
      class="absolute text-xs top-2 right-2 border border-black p-1 rounded"
    >
      Ad
    </span>
    <div
      :class="[
        'container h-full flex-grow min-h-[calc(100vh-320px)] flex mx-auto',
        'gap-6 py-6 text-xl sm:text-3xl font-bold flex flex-col text-white md:justify-evenly md:items-center',
      ]"
    >
      <div class="flex flex-col gap-4 md:flex-grow-0">
        <span
          class="text-4xl text-gray-800 font-bold inline-block font-dm flex gap-2 flex-col md:flex-row md:gap-8 md:justify-evenly md:items-center"
        >
          <span class="flex items-center">To get your plan,</span>
          <span
            class="text-red text-5xl md:text-8xl flex gap-2 md:items-center md:justify-center"
          >
            click to call
          </span>
          <span class="flex items-center">your licensed insurance agent</span>
        </span>
      </div>
      <div>
        <div ref="card" class="aspect-[380/253] max-w-[380px] relative">
          <img
            src="../../assets/allowance-card-blank.png"
            class="w-full h-full"
          />
          <div
            class="absolute left-[8%] bottom-[11%] w-[62%] text-white uppercase font-sans font-normal"
            :style="{
              fontSize: `${height * 0.1}px`,
              lineHeight: `${height * 0.15}px`,
            }"
          >
            <template v-if="name">
              <Typewriter :text="name" class="truncate" cursor="" :speed="75" />
            </template>
            <div v-else class="truncate"> Emily Taylor </div>
          </div>
        </div>
      </div>
      <div class="text-gray-600 text-sm font-sans text-center">
        Call now for licensed insurance agents
      </div>
      <div class="font-sans">
        <ClickToCall :props="{ ctcLocation: 'allowance-card-ctc' }">
          <template #text>
            Call {{ phone.formatted }}
            <br />
            TTY 711, 24/7
          </template>
        </ClickToCall>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh"],
    "robots": "noindex",
    "benefits": ["otc"]
  }
}
</route>
