<script setup lang="ts">
const isPaidUser = ref(true)
const router = useRouter()

const isMounted = useMounted()

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value && import.meta.env.MODE === 'production') {
    router.push('/go-4-benefits')
    return
  }

  enterExperiment('loConsigasImage')
})

const destination = computed(() => {
  if (!isMobile && import.meta.env.MODE === 'production') {
    return '/ads'
  }

  let value = '/additional-benefits-grocery-2024-es/call-now'

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  const minOtc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc ?? 0

  return {
    minOtcLtd,
    minOtc,
  }
})

analytics.page({
  name: 'OTC Bridge 5',
  category: 'LP',
  product: 'medicare',
})
</script>

<template>
  <Layout
    :hide-header="true"
    footer="2024"
    class="min-h-[100vh] relative flex flex-col"
  >
    <span
      class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
    >
      Ad
    </span>

    <div
      class="bg-green-600 pt-8 pb-4 px-4 text-3xl text-white text-center [text-shadow:1px_3px_2px_black] font-medium"
    >
      <div class="max-w-md mx-auto">
        Subsidio de comestibles <br />
        de ${{ stateConfig.minOtcLtd }} año
      </div>
    </div>

    <div>
      <div class="max-w-md mx-auto">
        <div class="aspect-[16/10] bg-black">
          <template v-if="isMounted">
            <img
              v-if="exp?.loConsigasImage?.fruitsBehind"
              src="../../assets/lo-consigas-fruits.png"
              class="w-full h-full object-cover"
            />
            <img
              v-else-if="exp?.loConsigasImage?.whiteHairWoman"
              src="../../assets/lo-consigas-white-hair.png"
              class="w-full h-full object-cover"
            />
            <img
              v-else
              src="../../assets/lo-consigas.png"
              class="w-full h-full object-cover"
            />
          </template>
        </div>
      </div>
    </div>

    <div class="py-4 px-4 bg-red-600 text-white text-center flex-1">
      <div class="max-w-md mx-auto space-y-4">
        <div class="text-xl font-medium [text-shadow:1px_2px_2px_black]">
          Es posible que seas elegible para un subsidio mensual de comestibles
        </div>
        <Button
          :to="destination"
          wide
          variant="yellow"
          @click="
            analytics.track('learnMoreClicked', {
              source: $route.fullPath,
              destination,
            })
          "
        >
          <span> Saber Más </span>
          <Icon
            i-heroicons-solid:arrow-right
            class="w-8 h-8 ml-6 animate-bounceHorizontal"
          />
        </Button>
        <div class="text-sm font-medium">
          ¿En medicare y medicaid? <br />
          *Disponible medicare advantage D-SNP
        </div>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge"],
    "language": "spanish",
    "robots": "noindex"
  }
}
</route>
