<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'

const plans = ref<PlanRowView[]>()
const loading = ref<boolean>(true)
const error = ref<boolean>(false)
const needMoreInformation = ref<boolean>(!forms.dentalAllowance24?.zipcode)

// enterExperiment('dentalAllowance24CallOption')

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
    }
  }
})

onMounted(async () => {
  const zipCode = forms.dentalAllowance24?.zipcode

  error.value = false
  try {
    plans.value = await getPlans({
      zipCode,
      carrier_groups: [],
      amount: 100,
      starting_from: 0,
    })
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <template v-if="loading && !error">
    <div class="flex items-center justify-center">
      <Spinner />
    </div>
  </template>
  <template v-else-if="!loading && error">
    <div class="text-xl text-red text-center font-medium">
      There's some errors while trying to find plans for you
    </div>
    <div class="text-xl text-center font-medium">
      Your licensed
      <span class="whitespace-nowrap">Insurance Agent</span>
      is ready to help
    </div>
    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Click to Call
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
  <template v-else>
    <div
      class="text-xl text-red text-center font-medium"
      v-if="needMoreInformation"
    >
      More Info Needed
    </div>
    <div
      class="text-xl text-red text-center font-medium"
      v-else-if="plans?.length && plans?.length > 0"
    >
      {{ plans?.length }} Plans Available to You
    </div>
    <div class="text-xl text-red text-center font-medium" v-else>
      No Plans Found
    </div>

    <div class="text-xl text-center font-medium">
      Continue w
      <span class="whitespace-nowrap flex items-center justify-center gap-2">
        <div class="relative flex w-4 h-4">
          <div
            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
          >
          </div>
          <div
            class="relative inline-flex rounded-full h-4 w-4 bg-green-500"
          ></div>
        </div>
        Licensed Insurance Agent
      </span>
    </div>

    <ClickToCall :props="{ ctcLocation: 'last-step' }">
      <template #text>
        Click to Call
        <br />
        TTY 711, 24/7
      </template>
    </ClickToCall>
  </template>
</template>
