<script setup lang="ts">
import { Step } from '~/modules/forms'
import variant1 from '../../assets/dental-low-copay-1.png'
import variant2 from '../../assets/dental-low-copay-2.png'
import variant3 from '../../assets/dental-low-copay-3.png'
import variant4 from '../../assets/dental-low-copay-4.png'
import variant5 from '../../assets/dental-low-copay-5.png'
import variant6 from '../../assets/dental-low-copay-6.png'

useHead({
  title: `Dental Allowance 24 - ${brand.name}`,
})

const isFromFacebook = query.utm_source?.toLowerCase() === 'facebook'

provide('isFromFacebook', isFromFacebook)

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
      minOtc: `$${state.minOtc}`,
      minOtcLtd: `$${state.minOtcLtd}`,
    }
  }
})

// enterExperiment('dentalAllowance24Description')
// enterExperiment('dentalAllowance24Image')

const imageObj = [
  '',
  variant1,
  variant2,
  variant3,
  variant4,
  variant5,
  variant6,
]
let key = 1
// if (exp.value?.dentalAllowance24Image) {
//   for (const k of Object.keys(exp.value.dentalAllowance24Image)) {
//     if ((exp.value.dentalAllowance24Image as any)[k as any] === true) {
//       let value = parseInt(k.replace('variant', ''), 10)
//       key = value
//     }
//   }
// }

const image = ref(variant1)
image.value = imageObj[key ?? 1]

const description = `Get dental care or dentures benefits.`
// computed(() => {
//   const description =
//     getCurentValidExperiment(exp.value?.dentalAllowance24Description) ??
//     'getDentalCare'
//   switch (description) {
//     default:
//       return `Get dental care or dentures benefits.`
//     case 'planCoverage':
//       return `Plan Coverage to smile about`
//     case 'stopAvoidDentist':
//       return `Stop avoiding the dentist. <br /> Shop plans with dental benefits.`
//     case 'shopForLowcost':
//       return `Shop for low-cost dental benefits.`
//     case 'shopPlans':
//       return `Shop plans that may protect your teeth. <br/>Check eligibility for a plan that may help protect your teeth.`
//     case 'coverageForRoutines':
//       return `Coverage for routine dental visits, <br/>Some major dental, or dentures
// `
//   }
// })

const currentStep = ref('')
const onChangeStep = (step: Step) => {
  currentStep.value = step.id
}
const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
analytics.page({
  name: 'Dental Allowance 24',
  category: 'LP',
  product: 'medicare',
})
</script>

<template>
  <Layout :hide-header="isFromFacebook" footer="giveback" class="container">
    <template #banner>
      <Banner> New to Medicare? On Medicaid? Moving? </Banner>
    </template>
    <template v-if="isFromFacebook">
      <div class="max-w-3xl mx-auto space-y-3 py-6">
        <h2
          class="font-bold text-xl text-red sm:text-2xl text-center flex flex-col"
        >
          2024 Medicare Advantage
          <span class="font-normal">DENTAL ALLOWANCE BENEFIT </span>
        </h2>
        <div class="overflow-hidden h-[180px] md:h-[220px]">
          <img
            src="../../assets/dental-low-copay-6.png"
            width="1849"
            height="1383"
            class="w-full sm:w-[400px] mx-auto -mt-16 scale-75"
            alt="Happy Senior lady in a dental chair"
          />
        </div>
        <div
          class="px-2 text-red text-4xl font-bold sm:text-3xl text-center flex flex-col"
        >
          <span>$1600/yr</span><span class="text-lg"> or more</span>
        </div>
        <div
          class="px-2 text-red text-xl font-semibold sm:text-3xl text-center flex flex-col"
        >
          Coverage for routine dental visits, Some major dental, or dentures
        </div>

        <div class="max-w-3xl mx-auto">
          <div
            v-if="currentStep === 'call'"
            class="flex flex-col shadow-md p-3 rounded bg-white gap-4"
          >
            <ClickToCall>
              <template #text>
                <span class="text-2xl sm:text-4xl"> CLICK TO CALL </span>
                <br />
                <span class="text-lg sm:text-3xl"> TTY 711, 24/7 </span>
              </template>
            </ClickToCall>
            <div class="flex items-start justify-start space-x-3">
              <div class="relative flex w-2 h-2">
                <div
                  class="animate-ping absolute mt-3 inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
                >
                </div>
                <div
                  class="relative inline-flex mt-3 rounded-full h-2 w-2 bg-green-500"
                >
                </div>
              </div>
              <div class="text-lg font-medium">
                Licensed Insurance Agents Available
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="max-w-3xl mx-auto space-y-6 py-6">
        <img
          :src="image"
          width="1849"
          height="1383"
          class="w-[90%] sm:w-[400px] mx-auto"
          alt="Happy Senior lady in a dental chair"
        />

        <div class="text-xl text-center text-red font-medium">
          <span class="text-blue">2024 Medicare Advantage</span>
          <span class="font-normal">DENTAL ALLOWANCE BENEFIT</span>
        </div>

        <div
          class="font-dm text-center font-medium border-2 border-red rounded-lg py-2 bg-red text-white max-w-sm mx-auto"
        >
          <div class="text-3xl text-white font-semibold"> $1600/yr </div>
          or more
        </div>

        <div
          class="text-xl text-red text-center font-medium"
          v-html="description"
        >
        </div>
      </div>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div
        class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white"
        v-if="!isFromFacebook || (currentStep !== 'call' && isFromFacebook)"
      >
        <Form
          id="dentalAllowance24"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'zipcode' },
            { id: 'medicare' },
            { id: 'medicaid', if: !isPaidUser },
            { id: 'call', completed: true },
          ]"
          product="medicare"
          @step-change="onChangeStep"
        />
      </div>
      <div class="text-xl text-blue text-center">
        Shop plans from carriers like <br />
        <span class="font-medium"> HUMANA, UNITEDHEALTHCARE® </span>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["dental"],
    "brands": ["bh"],
    "robots": "noindex",
    "smid": "MULTI-PLAN_HLWEB0124139_M"
  }
}
</route>
