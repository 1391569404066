<script setup lang="ts">
const isPaidUser = ref(true)
const router = useRouter()

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  if (!isPaidUser.value) {
    router.push('/go-4-benefits')
    return
  }
  enterExperiment('allowanceD5BHeaderImage')

  if (isMobile) {
    enterExperiment('allowancePlusBridgeMoreQuestions')
  }
})

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  return {
    minOtcLtd: minOtcLtd,
  }
})

const destination = computed(() => {
  if (!isMobile && import.meta.env.MODE === 'production') {
    return '/ads'
  }
  let value = exp.value?.allowancePlusBridgeMoreQuestions?.moreQuestions
    ? '/additional-benefits-grocery-2024'
    : '/additional-benefits-grocery-2024/call-now'

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    if (exp.value?.otcAdDestination) {
      const variant = exp.value.otcAdDestination.toBenefits
        ? 'toBenefits'
        : 'original'

      params.set('exp', `otc-ad-destination-2023-10-20@${variant}`)
    }

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})
analytics.page({
  name: 'Allowance Plus d5b',
  category: 'LP',
  product: 'medicare',
})
</script>
<template>
  <Layout
    header="blue"
    footer="2024"
    :is-ad="true"
    :secondary-image="exp?.allowanceD5BHeaderImage?.secondaryImage"
  >
    <div
      class="bg-[#305ab5] min-h-[100vh] gap-8 md:gap-12 font-sans relative py-6 overflow-hidden flex justify-center"
    >
      <div id="heart"></div>

      <div
        :class="[
          'container px-6 pt-5 h-full flex-grow flex mx-auto absolute top-4',
          'gap-4 text-xl sm:text-3xl font-bold flex flex-col text-black text-white ',
          'max-w-[800px]',
        ]"
      >
        <div>
          {{ USDollarFormatter(0).format(stateConfig.minOtcLtd)
          }}<span class="font-normal">/yr+</span> flexible allowance w some
          D-SNP plans
        </div>
        <div
          class="rounded-lg bg-white p-4 text-[#305ab5] text-lg flex gap-4 flex-col"
        >
          save on approved items like

          <div class="grid grid-cols-2 gap-4 px-2 allowance-items items-center">
            <img
              src="../../assets/allowance-d5b-dairy.png"
              aria-labelledby="groceries"
            />
            <span aria-label="groceries">
              groceries like meat, eggs, dairy, & more
            </span>
          </div>
          <div class="grid grid-cols-2 gap-4 px-2 allowance-items items-center">
            <img
              src="../../assets/allowance-d5b-gas.png"
              aria-labelledby="gas"
            />
            <span aria-label="gas"> gas at the pump </span>
          </div>
          <div class="grid grid-cols-2 gap-4 px-2 allowance-items items-center">
            <img
              src="../../assets/allowance-d5b-pharmacy.png"
              aria-labelledby="pharmacy"
            />
            <span aria-label="pharmacy"> over-the-counter pharmacy items </span>
          </div>
          <Button
            :to="destination"
            variant="blue"
            class="font-sans rounded-full! text-[#305ab5]! bg-[#FBBF21]! hover:bg-[#305ab5]! hover:text-white!"
            wide
            @click="
              analytics.track('learnMoreClicked', {
                source: $route.fullPath,
                destination: destination,
              })
            "
          >
            <span>Show Plans</span>
          </Button>
        </div>
        <div
          class="text-base md:max-w-3xl md:mx-auto px-4 w-full justify-center z-20 font-thin text-white"
        >
          some medicare advantage d-spn plans from well-known carriers like
          Humana and UnitedHealthcare&#174; include these additional benefits.
          click to check eligibility.
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.allowance-items {
  grid-template-columns: 4rem auto;
}
#heart {
  @apply absolute right-0 top-80;
  width: 100px;
  height: 90px;
  transform: scale(4);
}

#heart::before,
#heart::after {
  content: '';
  position: absolute;
  top: 0;
  width: 52px;
  height: 80px;
  border-radius: 50px 50px 0 0;
  background: #ffbe00;
}

#heart::before {
  left: 51px;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

#heart::after {
  left: -1px;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge"],
    "robots": "noindex",
    "benefits": ["otc"]
  }
}
</route>
