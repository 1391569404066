<script setup lang="ts">
import { TransitionPresets } from '@vueuse/core'
import { RouterLink, routerKey, useRoute } from 'vue-router'

const loading = ref(true)
const source = ref(0)

const isPaidUser = ref(true)

const router = useRouter()

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser

  if (!isPaidUser.value && import.meta.env.MODE === 'production') {
    router.push('/go-4-benefits')
    return
  }
})

const stateConfig = computed(() => {
  const minOtcLtd =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtcLtd ??
    500
  const minOtc =
    states.find((state) => state.code === ip.lookup?.stateCode)?.minOtc ?? 0

  return {
    minOtcLtd: minOtcLtd,
    minOtc: minOtc,
  }
})

onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy({ timeout: 2000 })
  source.value = 0
  setTimeout(() => {
    loading.value = false
    source.value = stateConfig.value?.minOtcLtd
  }, 500)
})

const output = useTransition(source, {
  duration: 2500,
  transition: TransitionPresets.easeInOutQuart,
})

const destination = computed(() => {
  if (!isMobile) {
    return '/ads'
  }
  let value = '/additional-benefits-grocery-2024-es/call-now'

  if (brand.id === 'bridge' && !import.meta.env.SSR) {
    const domain = 'https://benefit-helpline.com'
    const params = new URLSearchParams()

    Object.entries(getSessionEnhanced().query).forEach(([key, value]) => {
      if (value) {
        params.set(key, value)
      }
    })

    params.set('session_id', getSession().id)

    if (exp.value?.otcAdDestination) {
      const variant = exp.value.otcAdDestination.toBenefits
        ? 'toBenefits'
        : 'original'

      params.set('exp', `otc-ad-destination-2023-10-20@${variant}`)
    }

    const qs = params.toString() && `?${params.toString()}`

    value = domain + value + qs
  }

  return value
})

analytics.page({
  name: 'Allowance Card Bridge x4c',
  category: 'LP',
  product: 'medicare',
})

const shrinkClass = ref('')
const onTypewriterDone = () => {
  shrinkClass.value = ' !w-[190px]'
}
</script>
<template>
  <Layout
    :hide-header="true"
    footer="2024"
    class="bg-black flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative"
    ><span
      class="absolute text-xs top-2 right-2 border border-white text-white p-1 rounded z-20 pointer-events-none"
    >
      Ad
    </span>
    <div class="container md:max-w-1/2 max-w-full flex flex-col gap-4">
      <div class="flex justify-end">
        <AllowanceCard
          :target-value="stateConfig?.minOtcLtd"
          :class="['rotate-6 custom-transition w-[310px]', shrinkClass]"
          @done="onTypewriterDone"
        />
      </div>
      <div class="text-center">
        <div class="text-4xl text-white">
          ¿Ya tienes tu tarjeta de asignación de
        </div>
        <div class="text-6xl text-yellow py-2">
          {{ USDollarFormatter(0).format(output) }}
        </div>
        <div class="text-4xl text-yellow"> al año o más? </div>
      </div>

      <div
        class="w-full grid grid-cols-2 gap-4 max-w-[calc(100%-10px)] ml-1 mt-4 flex-grow"
      >
        <Button
          variant="undefined"
          :to="destination"
          :class="[
            'button w-full h-12 bg-red-500 border-red-400 cursor-pointer select-none !rounded-full border-[1px]',
            'active:translate-y-2 active:[box-shadow:0_0px_0_0_#f87171,0_0px_0_0_#f8717141] active:border-b-[0px] [box-shadow:0_10px_0_0_#f87171,0_15px_0_0_#f8717141]',
            'transition-all duration-150 ',
          ]"
        >
          <span
            class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
          >
            No
          </span>
        </Button>
        <Button
          variant="undefined"
          :to="destination"
          :class="[
            'button w-full h-12 bg-green-500 border-green-400 cursor-pointer select-none !rounded-full border-[1px]',
            'active:translate-y-2 active:[box-shadow:0_0px_0_0_#1ca54e,0_0px_0_0_#22c55e41] active:border-b-[0px] [box-shadow:0_10px_0_0_#1ca54e,0_15px_0_0_#22c55e41]',
            'transition-all duration-150 ',
          ]"
        >
          <span
            class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
          >
            Sí
          </span>
        </Button>
      </div>
      <img src="../../assets/fridge.png" />
    </div>
  </Layout>
</template>

<style scoped>
/* Tailwind does not have built-in 3D click effect, so we add custom CSS for that */
button:active {
  transform: translateY(4px);
}
.custom-transition {
  transition: width 0.5s;
}
</style>

<route lang="json">
{
  "meta": {
    "brands": ["bh", "bridge"],
    "language": "spanish",
    "robots": "noindex"
  }
}
</route>
